import { useParams } from "react-router-dom";

import { Box, Theme } from "@mui/material";

import { useBase64 } from "../../common/hooks/useBase64";
import { S3BucketKeys, S3BucketKeyType } from "../../common/utils/s3helper";
import { useGetUser } from "../../leak-finder/hooks/useGetUser";
import InvalidMediaType from "./components/errors/InvalidMediaType";
import ImageViwer from "./components/ImageViewer";
import VideoViewer from "./components/VideoViewer";
import { useMediaValidation } from "./hooks/useMediaValidation";

const MediaViewerPage = () => {
  useGetUser();

  const { base64, bucketKey } = useParams<{
    base64: string;
    bucketKey: string;
  }>(); // base64 string from the url

  const { decodeFromBase64 } = useBase64();

  // Decode the base64 string to get the s3 key
  const decodedUrl = decodeFromBase64(base64 ?? "");

  // Decode the bucket key from the url
  const decodedBucketKey = (decodeFromBase64(bucketKey ?? "") ??
    S3BucketKeys.NODE_FILES) as S3BucketKeyType;

  const { isValidFileExtension, extension } = useMediaValidation(decodedUrl);

  if (!isValidFileExtension) {
    return <InvalidMediaType />;
  }

  const isImage = extension === "jpg" || extension === "png";
  const isVideo = extension === "mp4";

  return (
    <Box
      sx={{
        backgroundColor: (theme: Theme) => theme.palette.otherBackground.main,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          height: "100vh",
        }}
      >
        {isImage && <ImageViwer s3Key={decodedUrl} />}

        {isVideo && (
          <VideoViewer s3Key={decodedUrl} s3BucketKey={decodedBucketKey} />
        )}
      </Box>
    </Box>
  );
};

export default MediaViewerPage;
