import { FC } from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import CardMediaVideoPlayerWithSpeedControls from "../../pages/media-viewer/components/player/CardMediaVideoPlayerWithSpeedControls";

interface VideoDialogProps {
  videoUrl: string | null;
  expanded: boolean;
  toggleExpand: () => void;
}

const VideoDialog: FC<VideoDialogProps> = ({
  videoUrl,
  expanded,
  toggleExpand,
}) => {
  const theme = useTheme();
  const { width: screenWidth } = useWindowDimensions();

  const getMaxWidth = () => {
    if (screenWidth > theme.breakpoints.values.xxl) {
      return theme.breakpoints.values.xl + "px";
    }

    return theme.breakpoints.values.lg + "px";
  };

  const getWidth = () => {
    return expanded ? "100%" : "100%";
  };

  return (
    <Dialog
      fullWidth
      open={expanded}
      onClose={toggleExpand}
      PaperProps={{
        sx: {
          width: getWidth(),
          maxWidth: getMaxWidth(),
          borderRadius: "8px",
        },
      }}
    >
      <Box sx={{ position: "absolute", zIndex: 3, right: 8, top: 8 }}>
        <IconButton onClick={toggleExpand}>
          <CloseIcon />
        </IconButton>
      </Box>

      {videoUrl && (
        <CardMediaVideoPlayerWithSpeedControls
          sx={{
            maxHeight: "calc(100vh - 64px)",
          }}
          controls
          src={videoUrl}
          autoPlay
        />
      )}
    </Dialog>
  );
};

export default VideoDialog;
